/*Global CSS*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
  background-color: #f9be62;
}

/*Font Families Import*/
@font-face {
  font-family: "Futura";
  src: local("Futura"), url("./fonts/Futura.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Nicholia";
  src: local("Nicholia"), url("./fonts/Nicholia.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins.ttf") format("truetype");
}

/*Global styling*/
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background-color: #e01073;
  z-index: 999999999;
  transform: translateX(100%);
  animation: shift-rightwards 1s ease-in-out infinite;
  animation-delay: 0.3s;
}

@keyframes shift-rightwards {
  0% {
    transform: translateX(-100%);
  }
  40% {
    transform: translateX(0%);
  }
  60% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

/*Root Element*/
:root {
  /* Fonts */
  --futura: Futura, sans-serif;
  --inter: Inter, sans-serif;
  --nicholia: Nicholia, sans-serif;
  --poppins: Poppins, sans-serif;
  --hind: Hind, sans-serif;

  /* Font-Sizes */
  --h1: 13rem; /*208px*/
  --h2: 9.063rem; /*145px*/
  --h3: 3.25rem; /*52px*/
  --h4: 3.125rem; /*50px*/
  --h5: 2.188rem; /*35px*/
  --h6: 1.375rem; /*22px*/
  --xxl: 1.5rem; /*24px*/
  --xl: 1.25rem; /* 20px */
  --lg: 1.125rem; /* 18px */
  --md: 1rem; /* 16px */
  --sm: 0.875rem; /* 14px */
  --xs: 0.813rem; /* 13px */
}

/*Media Queries for fonts*/
@media (min-width: 768px) and (max-width: 1023px) {
  :root {
  }
}

@media (max-width: 767px) {
  :root {
    --h1: 7.063rem;
    --h2: 5.25rem;
    --h5: 1.5rem;
    --xxl: 1.25rem;
    --xl: 1rem;
    --lg: 1rem;
    --md: 0.75rem; /* 16px */
  }
}

#_builder-form .form-builder--wrap-full .form-builder--wrap .fields-container {
  height: 100% !important;
  padding: 30px 0 0 0 !important;
}

#el_terms_and_conditions_3{
  margin-bottom: 0 !important;
}

.gohighlevel-form::-webkit-scrollbar{
  width: 0 !important;
  background-color: transparent;
}